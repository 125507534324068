var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.role !== 'developer' && _vm.role !== 'marketing' && _vm.role !== 'marketingv2')?_c('v-menu',{attrs:{"offset-y":"","max-height":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize lead-phase-badge",class:_vm.getLeadClass(
            _vm.lead.phase,
            _vm.lead.tracking_phase,
            _vm.lead.operation_phase,
            _vm.lead.internal_broker,
            _vm.lead.postponed,
            _vm.lead
          ),attrs:{"id":"no-background-hover","loading":_vm.loading,"disabled":_vm.loading,"rounded":"","elevation":"0","color":_vm.getLeadColor(_vm.lead.phase, _vm.lead.tracking_phase)}},'v-btn',attrs,false),on),[_c('div',[_vm._v(" "+_vm._s(_vm.getLeadPhaseContent( _vm.lead.phase, _vm.lead.tracking_phase, _vm.lead.operation_phase ))+" ")]),_c('v-icon',{staticClass:"px-1",attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,3909792132)},[_c('v-list',{attrs:{"elevation":"0"}},_vm._l((_vm.phasesLeads),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.sendUpdateLeadPhase(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1):_c('div',[(_vm.lead && _vm.lead.postponed !== undefined)?_c('v-chip',{staticClass:"lead-phase-badge",class:_vm.getLeadClass(
          _vm.lead.phase,
          _vm.lead.tracking_phase,
          _vm.lead.operation_phase,
          _vm.lead.internal_broker,
          _vm.lead.postponed,
          _vm.lead
        ),attrs:{"id":"no-background-hover","color":_vm.getLeadColor(_vm.lead.phase, _vm.lead.tracking_phase)}},[_vm._v(" "+_vm._s(_vm.getLeadPhaseContent( _vm.lead.phase, _vm.lead.tracking_phase, _vm.lead.operation_phase ))+" ")]):_c('v-chip',[_vm._v(" Asignado- ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }